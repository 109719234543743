import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import { graphql } from "gatsby"

import Schema from "../components/addon/schema"

const AdministrativeTermsConditions = ({ data: { allNodeTemplatePages: { edges } } }) => {
   
    const metaTags = edges[0]?.node?.metatag_normalized ? edges[0]?.node?.metatag_normalized : [];
    const schema = edges[0]?.node?.relationships?.field_seo_schema ? edges[0]?.node?.relationships?.field_seo_schema : []
    const canonicalUrl = edges[0]?.node?.metatag_normalized ? edges[0]?.node?.metatags_on_page : {};
    const terms = edges[0]?.node;
    const title = terms?.title || "";
    const details = terms?.relationships?.components[0]?.htmlText?.processed || "";
    return (
        <>
            <Layout>
                <Meta
                    files={{
                        js: [],
                        css: ["/assets/css/accessibility.css"],
                    }}
                    tags={{metaTags,canonicalUrl}}
                />
                 <Schema schema={schema} />
                <main className="innerpage">
                    <section className="section_bg pt-first-section pb-section">

                        <div class="container">
                            <div class="section-head">
                                <h1>{title ? title : "Administrative Terms Conditions"}</h1>
                            </div>
                        </div>

                        <div className="container">
                            <div className="content_card_box bg-white">
                                <div className="inner_card_content">
                                    <div dangerouslySetInnerHTML={{ __html: details ? details : "null" }} />
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export const query = graphql`
query administrativeTermsConditions {
allNodeTemplatePages(filter: {path: {alias: {regex: "/administrative-terms-conditions$/"}}}) {
edges {
node {
id
title
path {
alias
}
relationships{
    field_seo_schema{
        field_seo_title
        field_seo_schema_text_area
    }
}
metatag_normalized {
attributes {
content
name
property
}
}
metatags_on_page: field_meta_tags{
    canonical_url
  }
relationships {
components: field_component_type {
__typename
...ParagraphHTMLText
}
}
}
}
}
}`

export default AdministrativeTermsConditions